@import '../../common/common.scss';
.av-date-tabs {
    width: 100%;
    // border-bottom: 1px solid $gray-2;
    display: flex;
    flex-direction: row;
}

.av-date-tab {
    /* body 1 */
    font-family: MaisonNeue;
    font-style: normal;
    font-weight: normal;
    font-size: 1.25rem /*20px*/;
    line-height: 2.125rem /*34px*/;  
    height: 100%;
    display: inline-block;  
    cursor: pointer;
    // padding-bottom: .5rem;
    margin-right: 1.25rem;

    /* Auto Layout */

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-end;
    padding: 0.5em;

    border-radius: 4px;
    width: 118px;
}

.av-date-tab--active {
    height: 100%;
    color: #ffffff;
    background: #13819C;
    // border-bottom: 3px solid $blue-3;
}
.av-date-tab--inactive {
    height: 100%;
    background: #D1ECF3;
    // color: $gray-2;
}

.av-date-tab-left
{
    font-family: MaisonNeue;
    font-size: 12px;
}

.av-date-tab-right
{
    font-family: RecifeDisplayBook;
    font-weight: 500;
    font-size: 25px;
}
.tab-font-inactive
{
    font-family: RecifeDisplayRegular;
    font-weight: 500;
}

.av-agenda-container {
    margin-top: 1.875rem;
    padding-top: 1.875rem;
    padding-left: 16px;
    padding-right: 16px;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}


.av-agenda-container::-webkit-scrollbar{
    width: 5px;
    height: 5px;
    }
  
    .av-agenda-container::-webkit-scrollbar-thumb{
    background: #B3AFB3;
    border-radius: 10px;
    }
    .av-agenda-container::-webkit-scrollbar-thumb:hover{
    background: #B3AFB3;
    }
    .av-agenda-container::-webkit-scrollbar-track{
    background: #F0F0F0;
    border-radius: 0px;
    box-shadow: inset 0px 0px 0px 0px #F0F0F0;
    }


.av-agenda-container--limitedHeight {
    height: 75vh;
}
.av-agenda-item {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 1rem;
    border: 1px solid #3B3B3B;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 20px;
}
.av-item-time {    
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 1rem;
    /* label--primary */
    // font-family: MaisonNeue;
    // font-style: normal;
    // font-weight: normal;
    font-family: MaisonNeue;
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem /*14px*/;
    line-height: 1.25rem /*20px*/;
    letter-spacing: 0.0025rem /*0.4px*/;
    text-transform: uppercase;
    color: $gray-1;    
}
.av-item-time--hidden {
    visibility: hidden;
}

.av-item-info {
    width: 100%;
    // border-bottom: 1px solid $gray-3;
}
.av-item-info__title {
    /* h3 */
    font-family: RecifeDisplayBook;
    font-style: normal;
    font-weight: normal;
    font-size: 1.5rem;
    line-height: 1.75rem;
    display: flex;
    align-items: center;
    letter-spacing: -0.00625rem;
    margin-bottom: .75rem;
    margin-top: 0;
    color: #13819C;
    cursor: pointer;
}
.av-item-info__desc {
    margin-bottom: 1.25rem;
}
.av-item-info__desc,
.av-item-info__speaker {
    /* body2 */
    font-family: MaisonNeue;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem /*16px*/;
    line-height: 1.25rem;
    color: $gray-1;
}

.av-item-buttons {
    flex-grow: 1;
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    
}
.av-button {
    cursor: pointer;
    /* margin-top: 1.5rem;
    margin-left: 1rem; */
    display: inline-block;
    height: 32px;
    margin: 0 1rem 1rem 1rem;
    
}
.av-button__calendar {
    
}
.av-button__plus {
    /* position: relative;
    right: 1rem;
    top: 1.5rem; */
}
.av-button__plus--checked {

}
.av-agenda-loading {
    width: 100%;
    text-align: center;
    /* h2 */
    font-family: RecifeDisplayBook;
    font-style: normal;
    font-weight: normal;
    font-size: 2.25rem; /*36px*/
    line-height: 2.75rem; /*44px*/    
    letter-spacing: -.0025rem; /*-0.4px*/
}
.av-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);

    width: 800px;
    height: 683px;
    max-height: 95vh;

    background: #fff;
    outline: none;
    cursor: unset;
}
.av-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(32, 62, 104, 0.5);
    cursor: pointer;
    z-index: 200;
    cursor: unset;
}
.av-modal-close-button {
    display: none;
}
.av-modal-time {
    color: $gray-1;
    opacity: 0.8;
}
.av-modal-content {
    overflow: auto;
    height: 100%;
    padding: 40px;
}
.av-modal-header {
    display: flex;
    flex-direction: row;
}
.av-modal-button {
    display: flex;
    flex-direction: row;
}
.av-modal-title {
    flex-grow: 1;
    color: $gray-1;
    opacity: 1;
}
.av-modal-text {
    color: $gray-1;
    opacity: 0.8;
}
.av-modal-sep {
    color: $gray-3;
}
.av-modal-speaker-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px
}
.av-modal-speaker {
    grid-template-columns: repeat(2, 1fr);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.av-modal-speaker-name {
    font-family: RecifeDisplayBook;
    color: #000000;
}
.av-modal-speaker-title {
    padding-top: 8px;
    color: $gray-1;
}
.av-modal-bottom-buttons {
    display: none;
}
.av-modal-bottom-calendar-button {
    flex: 1;
    background: $blue-3;
    color: white;
}
.av-modal-bottom-agenda-button {
    flex: 1;
    color: white;
    background: $blue-3;
}
.av-modal-close {
    cursor: pointer;
}
.av-modal-close-button {
    display: block;
    float: right;
    background: none;
    outline: none;
    border: none;
    cursor: pointer;    
    position: absolute;
    top: 18px;
    right: 24px;
}
@media screen and (max-width:800px) {
    .av-modal {
        width: 100%;
        height: 100%;
    }
    
    .av-modal-button {
        display: none;
    }
    .av-modal-content {
        padding-bottom: 100px;
    }
    .av-modal-bottom-buttons {
        position: absolute;
        width: 100%;
        bottom: 0;
        display: flex;
        flex-direction: row;
    }
    .av-modal-speaker-grid {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }
    .av-item-buttons {
        position: relative;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: flex-start;

    }
    .av-button {
        cursor: pointer;
        /* margin-top: 1.5rem;
        margin-left: 1rem; */
        display: inline-block;
        width: 32px;
        margin-bottom: 1rem;

    }
}