.feature-video-card {
  &__card {
    //height: 400px;
    background-color: white;
    display: flex;
    flex-direction: column;
    max-width: 450px;
    min-width: 230px;
  }

  &__image {
    position: relative;
    flex: 50%;
    height: 100%;
    //background-color: maroon;
    object-fit: cover;
  }

  &__thumbnail {
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__play-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 45px;
    width: 50px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__content {
    padding: 1rem 1rem;
    flex: 50%;
  }

  &__title {
    //margin-bottom: 0.5rem;
    width: 100%;
    margin-bottom: 0.5rem;
  }

  &__title-2 {
    margin-bottom: 1rem;
  }

  &__text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}

@media screen and (min-width: 990px) {
  .feature-video-card {
    &__card {
      min-width: 260px;
      //max-width: 364px;
      //height: 435px;
    }

    &__image {
    }

    &__content {
      padding: 1rem 1rem;
      min-height: 234px;

      .content-wrapper {
        max-width: 300px;
      }
    }
  }
}

@media screen and (min-width: 1440px) {
  .feature-video-card {
    &__card {
    }

    &__image {
      min-height: 200px;
    }

    &__content {
      .content-wrapper {
        max-width: 364px;
      }
    }
  }
}

// @media screen and (min-width: 1700px) {
//   .feature-video-card {
//     &__card {
//     }

//     &__content {
//       padding: 2rem 2rem;
//       .content-wrapper {
//         width: 600px !important;
//       }
//     }
//   }
// }
