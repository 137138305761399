@import '../../common/common.scss';

.video-info {
  width: 100%;

  height: 100%;

  &__public-page {
    height: 745px;
  }

  &__vid-desc {
    align-items: center;
    width: 100%;
    padding-bottom: 4rem;
    background-color: $gray-1;
    display: block;
    padding-left: 1.25rem;
    padding-top: 1.25rem;
    padding-right: 1.25rem;
  }

  &__vid-desc-content {
    padding-bottom: 4rem;
  }

  .underline {
    border-bottom: 1px solid white;
  }

  .dark {
    background-color: $dark-bg;
  }

  .light {
    background-color: #014656;
  }

  .background {
    background-image: url('/images/bg-blue-box.png');
    background-repeat: no-repeat;
    background-position: bottom right;
  }

  &__header {
    margin-bottom: 1rem;
  }

  &__desc {
    //border: 1px solid maroon;
    flex-direction: column;
  }

  &__text {
    margin-bottom: 2rem;
  }

  &__title {
    margin-bottom: 1rem;
  }

  &__speaker {
    display: flex;
    flex-direction: row;
    align-items: center;
    //border: 1px solid green;
    margin-top: 0.8rem;
  }

  &__mobile-title {
    max-width: 276px;
    padding-top: 3.25rem;
  }

  &__speaker-image {
    margin-right: 1rem;
    img {
      height: 4rem;
      width: 4rem;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  &__about-speaker {
    flex-direction: column;
  }

  &__speaker-name {
    margin-bottom: 0.625rem;
  }

  &__upcoming {
    margin-top: 1.5rem;
  }

  iframe {
    width: 100%;
    height: 100%;
  }
}

@media only screen and (min-width: 765px) {
  .video-info {
    &__vid-desc {
      padding-left: 3.25rem;
      padding-top: 3.25rem;
      padding-right: 3.25rem;
    }
  }
}

@media only screen and (min-width: 990px) {
  .video-info {
    flex: 3;

    height: 838px;

    &__desktop-title {
      max-width: 600px;
    }

    &__vid-desc {
      align-items: center;
      width: 100%;
      height: 838px;
      padding-bottom: 4rem;
      background-color: $gray-1;
      display: block;
      padding-left: 2.25rem;
      padding-top: 2.25rem;
      padding-right: 2.25rem;
    }

    &__vid-desc-content {
      padding-bottom: 3rem;
    }

    &__desc {
      //border: 1px solid maroon;
      flex-direction: column;
    }

    &__text {
      margin-bottom: 4rem;
    }

    &__title {
      margin-bottom: 1rem;
    }

    &__speaker {
      margin-right: 1rem;
    }

    &__speaker-name {
      margin-bottom: 0.625rem;
    }

    &__upcoming {
      margin-top: 1.5rem;
    }

    iframe {
      height: 750px;
    }

    p {
      margin-top: 1rem;
    }

    .speaker-container {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      gap: 0.2rem;
    }
  }
}

@media only screen and (min-width: 1440px) {
  .video-info {
    &__vid-desc {
      padding-left: 9.25rem;
      padding-top: 5.25rem;
      padding-right: 9.25rem;
      padding-bottom: 5.25rem;
    }

    .speaker-container {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
      gap: 2rem;
    }

    .speaker {
      flex-basis: 50%;
    }
  }
}

@media only screen and (min-width: 1660px) {
  .video-info {
    .speaker-container {
      grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
      gap: 2rem;
    }
  }
}
