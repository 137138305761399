#invite {
  // transition: opacity .15s cubic-bezier(0.4,0.0,0.2,1) .15s;
  // background-color: rgba(0,0,0,0.6);
  // bottom: 0;
  // left: 0;
  // // opacity: 1;
  // position: fixed;
  // right: 0;
  // top: 0;
  // z-index: 5000;
  // -webkit-tap-highlight-color: transparent;

  .prompt {
    max-width: 400px;
    // min-height: 200px;
    min-width: 400px;
    box-shadow: 0 1px 3px 0 rgba(60, 64, 67, 0.302),
      0 4px 8px 3px rgba(60, 64, 67, 0.149);
    -webkit-box-align: stretch;
    box-align: stretch;
    align-items: stretch;
    display: flex;
    -webkit-box-orient: vertical;
    box-orient: vertical;
    flex-direction: column;
    transition: transform 0.225s cubic-bezier(0, 0, 0.2, 1);
    position: relative;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24);
    max-width: 24em;
    outline: 1px solid transparent;
    overflow: hidden;
    flex-shrink: 1;
    max-height: 100%;
    margin: auto;
    top: 40%;

    .top {
      font-family: Roboto, Arial, sans-serif;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0.1px;
      line-height: 24px;
      display: flex;
      flex-shrink: 0;
      padding: 24px 24px 20px 24px;

      .title {
        box-flex: 1;
        flex-grow: 1;
        flex-shrink: 1;
        overflow: hidden;
        word-wrap: break-word;
      }

      .close {
        // margin: -12px -16px 0 0;
        flex-shrink: 0;
        display: block;
        // margin: -12px -6px 0 0; .
        color: rgba(0, 0, 0, 0.54);
        fill: rgba(0, 0, 0, 0.54);
        -webkit-user-select: none;
        transition: background 0.3s;
        border: 0;
        border-radius: 50%;
        cursor: pointer;
        display: inline-block;
        flex-shrink: 0;
        height: 30px;
        outline: none;
        overflow: hidden;
        position: relative;
        text-align: right;
        -webkit-tap-highlight-color: transparent;
        width: 48px;
        z-index: 0;
      }
    }

    .description {
      font-family: Roboto, Arial, sans-serif;
      color: #5f6368;
      margin-bottom: 12px;
      padding: 0 24px;
      font-size: 15px;
    }

    .link-holder {
      -webkit-box-align: center;
      box-align: center;
      align-items: center;
      background: #f1f3f4;
      border-radius: 4px;
      color: #5f6368;
      display: flex;
      padding-left: 12px;
      margin: 0 24px 20px 24px;

      .link {
        letter-spacing: 0.00625em;
        font-family: Roboto, Arial, sans-serif;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5rem;
        color: #202124;
        box-flex: 1;
        flex-grow: 1;
        overflow-x: scroll;
      }

      .copy {
        color: #5f6368;
        fill: #5f6368;
        -webkit-user-select: none;
        transition: background 0.3s;
        border: 0;
        border-radius: 50%;
        cursor: pointer;
        flex-shrink: 0;
        height: 48px;
        outline: none;
        overflow: hidden;
        position: relative;
        text-align: center;
        -webkit-tap-highlight-color: transparent;
        width: 48px;
        z-index: 0;
        display: grid;
        align-items: center;
        justify-content: center;
      }
    }
  }

  textarea {
    opacity: 0;
  }
}
