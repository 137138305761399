@import '../../common/common.scss';
/* Mobile First Styles */
.rwip-habitat-logo {
    margin-top: 0.6em;
    margin-bottom: 0.6em;
    margin-left: 1em;
    vertical-align: middle;    
}

.rwip-need-help {
    display: none;
    background-color: #1EA8D0;
}

.rwip-container {
    height:100%;
    background-color: #1EA8D0;
}

.rwip-step-title {
    font-family: RecifeDisplayBook;
    font-style: normal;
    font-weight: normal;
    font-size: 1.5rem /*24px*/;
    line-height: 1.75rem /*28px*/;    
    letter-spacing: -0.00625rem /*-0.1px*/;
    color: white;
    margin-bottom: 3rem;
}
.rwip-step-title--incomplete {
    opacity: 0.5;
    display: none;
}
.rwip-step-title--complete {
    color: $brand-blue-2;
    display: none;
}
.rwip-steps-img__img {
    display:none;
}
.rwip-steps-container {
    display: none;
}
.tc-overflow p {
    margin-block-start: 1em;
    margin-block-end: 1em;
}

@media (max-height:720px) {
    .rwip-steps-img__img {
        display: none !important;
    }
}



@media (min-width:960px) {
    .rwip-need-help {
        display: unset;
        position: absolute;
        bottom: 0;
        left: 0;
        padding-bottom: 1.5em;
        padding-left: 2em;
        background-color: #1EA8D0;
        color: #FFFFFF;
        text-align: center;
    }

    .rwip-habitat-logo {
        margin-top: 2.4em;
        margin-left: 2em;
    }
    .rwip-steps-img {
        position: absolute;
        bottom: 7.5rem;
        left: 70px;

    }
    .rwip-steps-img__img {
        width: 225px;
        display: block;
    }
    .rwip-step-title--incomplete,
    .rwip-step-title--complete {
        display: block;
    }
    .rwip-steps-container {
        margin-left: 2.5rem;
        margin-top: 4rem;
        display: block;
    }
    
}
/**/