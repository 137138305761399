@import '../../../../../common/common.scss';

.rooms-card {
  background-color: white;
  padding: 2rem 1rem;
  max-width: 444.8px;
  &__content {
    align-items: flex-start;
    justify-content: space-between;
  }

  &__image-text {
    display: flex;
    img {
      width: 2.625rem;
      height: 2.625rem;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  &__info {
    padding-left: 20px;
  }

  &__title {
    width: 150px;
    height: 40px;
    word-wrap: break-word;
    margin-bottom: 0.625rem;
  }

  &__room-name {
    width: 200px;
    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
    word-wrap: break-word;
    margin-bottom: 1rem;
  }

  &__number {
    margin-bottom: 1.3rem;
  }

  &__cta {
    align-self: flex-end;
  }

  &__pop {
    background-color: $red-6;
    color: $red-1;
    padding: 0.2rem 0.5rem;
  }

  .filled-room{
    background-color: $gray-3;
    color: $gray-1;
    padding: 0.2rem 0.5rem;
  }

}

@media screen and (min-width: 1600px) {
  .rooms-card {
    &__pop {
      width: 130px;
      text-align: center;
    }
  }
}
