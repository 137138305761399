@import '../../common/common.scss';

.vhhp-button {
  padding: 1rem 2rem;
  font-family: MaisonNeue;
  line-height: 1.25rem /*20px*/;
  font-size: 1rem;
  border: none;
  outline: none;
  box-sizing: border-box;
  border-radius: 0;
  cursor: pointer;
}

.vhhp-button--white {
  color: white;
}
.vhhp-button--gray {
  color: $gray-1;
}
.vhhp-button--blue {
  color: $blue-3;
}
.vhhp-button--bg-gray {
  background-color: $gray-5;
}
.vhhp-button--bg-gray:hover,
.vhhp-button--bg-gray:focus {
  background-color: $gray-3;
}

.vhhp-button--bg-blue {
  // background-color: $blue-3;
  background-color: #13819C;
}
.vhhp-button--bg-blue:hover,
.vhhp-button--bg-blue:focus {
  background: $blue-2;
}

.vhhp-button--bg-dark-blue {
  // background-color: $blue-2;
  background-color: #1EA8D0;
}
.vhhp-button--bg-dark-blue:hover,
.vhhp-button--bg-dark-blue:focus {
  background: $blue-1;
}

.vhhp-button--bg-gold {
  background-color: $gold-6;
}
.vhhp-button--bg-gold:hover,
.vhhp-button--bg-gold:focus {
  background: $gold-5;
}

.vhhp-button--bg-light-green {
  background-color: $green-6;
}
.vhhp-button--bg-light-green:hover,
.vhhp-button--bg-light-green:focus {
  background: $green-5;
}

.vhhp-button--bg-dark-green {
  background-color: $green-1;
}
.vhhp-button--bg-dark-green:hover,
.vhhp-button--bg-dark-green:focus {
  background: $green-2;
}

.vhhp-button--bg-transparent {
  background-color: transparent;
}

.vhhp-button--outline-gray {
  border: 1px solid $gray-1;
}
.vhhp-button--outline-white {
  border: 1px solid white;
}
.vhhp-button--outline-blue {
  border: 1px solid $blue-3;
}
