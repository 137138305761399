.agenda-container {
  width: 100%;
  max-height: 1230px;
  display: flex;
  flex-direction: row;
  position: relative;
  background-color: #ffffff;
  // background-image: url('./img/wbs_agenda-background-blurred.jpg');
  // background-size: cover;
  // background-repeat: no-repeat;
  // background-position: top center;

  // &::before {
  //   content: '';

  //   opacity: 1;
  //   position: absolute;
  //   top: 0px;
  //   left: 0px;
  //   right: 0px;
  //   bottom: 0px;
  // }
}
.agenda-overlay {
  position: absolute;
  // background: rgba($color: black, $alpha: 0.55);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.agenda-content {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px 50px 50px 50px;
  /* // margin-left: 9.75rem;
  // margin-right: 9.75rem;
  // margin-top: 6.25rem;
  // margin-bottom: 4.25rem; */
}
.agenda-content_header {
  margin-bottom: 1.25rem;
}
.agenda-content_title {
  color: #FC5420;
  margin-bottom: 4rem;
}
.agenda-content_agenda {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.agenda-content_cta {
  border: 1px solid white;
  margin-bottom: 2rem;
  width: 257px;
  padding: 1rem 0;
}
.pav-bg-img
{
  width: 100%;
}
.download_cta {
  display: flex;
  justify-content: center;

  color: white !important;

  img {
    padding-right: 0.5rem;
  }
}

.agenda-content_cta p {
  padding-left: 1rem;
}

.agenda-content_agenda-container-left {
  display: none;
  width: 50%;
}
.agenda-content_agenda-container-right {
  display: none;
  width: 50%;
}
.agenda-left {
  height: 890px;
  width: 100%;
  margin-right: 0.75rem;
}
.agenda-right {
  height: 890px;
  width: 100%;
  margin-left: 0.75rem;
}

.agenda-content_agenda-container-middle {
  display: block;
  width: 100%;
}

@media screen and (max-width: 990px) {
  .pav-bg-img
  {
    display: none;
  }
  .agenda-container {
    // max-height: unset;
    max-height: 880px;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
  }
  .agenda-content_agenda-container-middle {
    display: block;
    width: 100%;
    height: 80%;
  }
  .agenda-content_agenda-container-right,
  .agenda-content_agenda-container-left {
    display: none;
    width: unset;
  }
  .agenda-left {
    height: 640px;
    margin-right: unset;
  }

  .agenda-active,
  .agenda-inactive {
    border-top: none;
  }
  .agenda-content {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0.875rem;
    margin-bottom: 0;
  }
  .agenda-content_header {
    margin-top: 3rem;
    margin-left: 1.25rem;
    margin-right: 1.25rem;

    /*becomes label--secondary*/
    font-size: 0.6875rem;
    line-height: 1rem;
    letter-spacing: 0.04375rem;
  }
  .agenda-content_title {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    margin-bottom: 2.5rem;

    /* becomes h2 */
    font-size: 2.25rem;
    line-height: 2.75rem;
    letter-spacing: -0.0025rem;
  }

  .agenda-content_cta {
    margin-left: 1.25rem;
  }
}

@media screen and (min-width: 990px) {
  .agenda-section {
    display: flex;
    flex-direction: column;
  }

  .agenda-container {
    order: 1;
    position: relative;
  }

  .agenda-container .gradient-overlay {
    position: absolute;
    bottom: -5%;
    left: 0;
    z-index: 0;
    width: 100%;
  }

  .feature-section {
    order: 2;
  }
}

@media screen and (min-width: 1440px) {
  .agenda-active {
    border-top: 10px solid #00afd7;
  }
  .agenda-inactive {
    border-top: 10px solid white;
  }
}
