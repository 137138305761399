@import '../../../common/common.scss';

.single-video {
  &__container {
    background-color: $gray-1;
  }

  &__video {
    padding: 2rem 0 1rem 0;
  }

  &__content-block {
    padding: 2rem 1.25rem 1rem 1.25rem;
  }

  &__thumbnail {
    position: relative;
    width: 100%;
    height: 55vw;
    max-height: 628px;
    padding-bottom: 56.25%; /* proportion value to aspect ratio 16:9 (9 / 16 = 0.5625 or 56.25%) */
    //height: 0;
    overflow: hidden;

    iframe {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__play-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0.5rem 0.5rem 0.5rem 1rem;
    background-color: white;

    svg {
      transform: scale(0.6);
    }
  }

  &__content {
    flex-direction: column;
    border-bottom: 1px solid white;
    width: 100%;
    padding-bottom: 1rem;
  }

  &__desc {
    //border: 1px solid maroon;
    margin-bottom: 0.5rem;
    flex-direction: column;
  }

  &__title {
    margin-bottom: 1rem;
  }

  &__text {
    margin-bottom: 1rem;
  }

  &__side-desc {
    //border: 1px solid magenta;
    flex-direction: column;
  }

  &__speakers {
    max-width: 440px;
  }

  &__speaker {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 0.8rem;
  }

  &__speaker-image {
    margin-right: 1rem;
    img {
      height: 4rem;
      width: 4rem;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  &__about-speaker {
    flex-direction: column;
  }

  &__speaker-name {
    margin-bottom: 0.625rem;
  }

  &__related-videos {
    padding-top: 1.5rem;
    padding-bottom: 2rem;
  }

  &__related-header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 2rem;
  }

  &__related-title {
    margin-bottom: 1rem;
  }

  &__see-more {
    align-items: center;

    a {
      margin-right: 1rem;
    }
  }

  &__related-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-column-gap: 1rem;
    grid-row-gap: 1.5rem;
  }

  // &__sponsor-menu {
  //   background-color: $gold-6;

  //   .alice-carousel {
  //     &__dots-item {
  //       background-color: rgba($color: $gray-1, $alpha: 0.3) !important;
  //     }

  //     &__dots {
  //       .__active {
  //         background-color: $gray-1 !important;
  //       }
  //     }
  //   }
  // }
}

@media screen and (min-width: 765px) {
  .single-video {
    &__content {
      padding-bottom: 3.5rem;
    }

    &__video {
      padding: 2rem 0 3.5rem 0;
    }
    &__content-block {
      padding: 2rem 1.25rem 1rem 1.25rem;
    }

    &__side-desc {
      margin-top: 1.5rem;
    }

    &__related-videos {
      padding-top: 3.5rem;
    }

    &__related-header {
      flex-direction: row;
    }
  }
}

@media screen and (min-width: 990px) {
  .single-video {
    &__container {
      padding: 0 2rem;
    }
    &__video,
    &__content-block {
      padding: 2rem 3.25rem 5rem 3.25rem;
    }

    &__play-btn {
      padding: 1rem 1rem 1rem 1.5rem;

      svg {
        transform: scale(0.8);
      }
    }

    &__content {
    }

    &__text {
      margin-bottom: 0;
    }

    &__content {
      padding-bottom: 4rem;
    }

    &__related-videos {
      padding-top: 4rem;
      padding-bottom: 4rem;
    }

    &__related-header {
      flex-direction: row;
      align-items: flex-end;
      margin-bottom: 4rem;
    }

    &__related-title {
      margin-bottom: 0rem;
    }

    &__see-more {
      a {
      }
    }

    &__related-cards {
      grid-column-gap: 2rem;
    }
  }
}

@media screen and (min-width: 1440px) {
  .single-video {
    &__video,
    &__content-block {
      padding: 2rem 4.25rem 5rem 4.25rem;
    }

    &__desc {
      max-width: 665px;
    }

    &__content {
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
    }

    &__side-desc {
      margin-right: 1rem;
    }

    &__related-cards {
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      grid-column-gap: 2rem;
    }
  }
}

@media screen and (min-width: 1600px) {
  .single-video {
    // &__width
    // {
    //   max-width: 1500px;
    //   display: flex;
    //   flex-direction: column;
    //   justify-content: center;
    // }

    &__video {
      padding: 2rem 0rem 5rem 0rem;
    }

    &__content-block {
      padding: 2rem 0rem 5rem 0rem;
    }

    &__side-desc {
      margin-right: 3rem;
    }

    &__container {
      padding-left: 8.25rem;
      padding-right: 8.25rem;
    }
  }
}
