#chat {
  background: rgba(0, 0, 0, 0.5);
  color: white;
  position: absolute;
  top: 0;
  right: 0;
  // width: 30%;
  // height: calc(800px / 100 * 80 );
  width: 100%;
  height: 100%;
  //height: 100%;
  display: flex;
  flex-direction: column;

  &.conference {
    width: 30% !important;
    height: calc(800px / 100 * 80) !important;
  }

  .tabs {
    padding: 15px;
    padding-top: 0;

    .tabs-wrapper {
      display: flex;
      border-bottom: 1px solid rgba(255, 255, 255, 0.25);

      .tab {
        line-height: 20px;
        padding: 15px 0;
        margin-right: 20px;
        cursor: pointer;
        border-bottom: 3px solid;
        border-color: transparent;
        transition: all 0.2s ease-in;

        &.active {
          border-color: #ffffff;
        }

        &:not(.active):not(:hover) {
          opacity: 0.5;
        }
        .new-msg-label--secondary {
          margin-top: 5px;
          margin-left: 15px;
          height: 24px;
          width: 24px;
          border-radius: 50%;
          background-color: #ef473e;
          text-align: center;
          line-height: 24px;
          padding: 5px;

          font-style: normal;
          font-weight: 500;
          font-size: 0.6875rem /*11px*/;
          line-height: 1rem /*16px*/;
          letter-spacing: 0.04375rem /*0.7px*/;
          text-transform: uppercase;
        }
      }

      .users {
        flex-grow: 1;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;

        img {
          margin-right: 10px;
        }
      }
    }
  }
}
