//Modal wrapper
.modal {
  position: fixed;
  background: rgba($color: black, $alpha: 0.5);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  //overflow: scroll;
  z-index: 100;

  &__content {
    position: absolute;
    top: 50%;
    left: 50%;
    // box-shadow: 0 12px 30px rgba(0, 0, 0, 0.3);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    margin-bottom: 60px;
    max-width: 670px;
    width: 100%;
    padding: 1.25rem;
  }

  &__close {
    position: absolute;
    top: -1.2rem;
    right: 1.5rem;
    color: white;
    cursor: pointer;
  }
}

// to hide the scrollbar from the modal
// .modal::-webkit-scrollbar {
//   width: 0px;
//   background: transparent; /* make scrollbar transparent */
// }

// .modal {
//   -ms-overflow-style: none; /* IE and Edge */
//   scrollbar-width: none; /* Firefox */
// }
