.chatbox {
    overflow-y: scroll;
    flex-grow: 1;
    padding: 15px;
    padding-top: 0;
    padding-bottom: 0;

    .chat-message {
        display: flex;
        margin-bottom: 10px;

        .thumbnail {
            padding: 10px;

            img {
                height: 36px;
                width: 36px;
                border-radius: 100%;
            }
        }

        .content {
            flex-grow: 1;
            padding: 10px 40px 10px 10px;
            width: 50%;
            word-wrap: break-word;

            .name {
                opacity: 0.5;
            }
        }
    
        .time {
            opacity: 0.25;
            white-space: nowrap;
        }

    }

    .chat-filler {
        justify-content: center;
        display: flex;
        align-items: center;
        height: 100%;
    }

}

.typer {
    // padding: 15px;
    // padding-top: 0;
    // padding-bottom: 0;
    border-top: 1px solid rgba(255, 255, 255, 0.25);

    

    .messageForm {
        display: flex;
        align-items: center;
        width: 100%;

        .emoji {
            position: relative;
            padding: 0 2rem;
            cursor: pointer;

            &-picker{
                position: absolute;
                top: -365px;
                left: 0;
            }
        }
    
        .input-container{

            flex-grow: 1;
           
            input {
                padding: 15px;
                background-color: transparent;
                opacity: 0.5;
                border: 0;
                
                color: white;
                box-shadow: none;
                outline: 0;

                width: 100%;
            }
        }



    }

    

}
