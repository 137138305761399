@import '../../common/common.scss';

.ig-copy-container {

}

.ig-copy-copied {
    width: 100%;
    text-align: center;
    margin-top: 2.5rem;
    padding-top: 8px;
    padding-bottom: 8px;
    background-color: $brand-blue-2;
    font-family: MaisonNeue;
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.5rem;
}
.ig-copy-copied__img {
    vertical-align: middle;
    margin-right: 1rem;
}

.ig-copy-box {    
    width: 100%;
    background-color: $gray-5;
}

.ig-copy__title {
    padding-top: 2.5rem;
    margin-bottom: 0.625rem;
    text-align: center;
    /* H3 */
    font-family: RecifeDisplayBook;
    font-style: normal;
    font-weight: normal;
    font-size: 1.5rem /*24px*/;
    line-height: 1.75rem /*28px*/;    
    letter-spacing: -0.00625rem /*-0.1px*/;
}

.ig-copy__text {
    text-align: center;
    margin-bottom: 2rem;
}

.ig-copy-buttons {
    text-align: center;
    padding-bottom: 2.5rem;
}
.ig-copy-buttons__button {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    margin-bottom: 1rem;
    width: 180px;
}

.ig-error--hidden {
    visibility: hidden;
}
.ig-copy-error-hidden {
    display: none;
}