@import '../../../../common/common.scss';

.bio-container {
    // background-image: url('/images/bio-top.jpg');
    
    // background-repeat: no-repeat;
    // padding-top: 200px;
    padding-left: 5.25rem;
    background-color: white;

   
}


.speaker-bio{
    width: 900px;
    height: 400px;

    &__header-image {
        background-color: $brand-blue-2;
        width: 100%;
        height: 120px;
        background-image: url('../../../../images/bio-top.jpg');
        background-repeat: no-repeat;
        background-position: bottom right;
        margin-bottom: 1rem;
    }

    &__header{
        display: flex;
        align-items: center;
        margin-bottom: 1.5rem;
    }

    &__image {
        margin-right: 2.5rem;
        img{
            width: 110px;
            height: 110px;
            border-radius: 50%;
            object-fit: cover;
        }
    }
}