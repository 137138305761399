@import '../../../common/common.scss';

.register-text
{
  color: #ffffff;
}
.register-section-container
{
  width: 100%;
  min-height: 569px;
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 220px;
  background-color: #1EA8D0;
  background-image: url('./img/register-bg-icon.png');
  background-position: top left;
  background-repeat: no-repeat;
  background-size: 100%;
  padding-bottom: 80px;
}

.register-container {
  width: 100%;
  height: 600px;
  display: flex;
  flex-direction: row;
  background-color: $green-1;
}
.register-tl-box {
  width: 45%;
  height: 100%;
  flex: 1 1 auto;
}
.register-tl-box-content {
  margin-left: 9.75rem;
  margin-right: 6.25rem;
  margin-top: 6.25rem;
}
.register-tl-box_title {
  margin-bottom: 1rem;
}
.register-tl-box_body {
  margin-bottom: 3rem;
}
.register-br-box {
  width: 55%;
  height: 100%;
  flex: 1 1 auto;
}
.register-br-box_img-container {
  height: 100%;
  width: 100%;
}
.register-br-box_img-container_img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  display: block;
}
@media screen and (max-width: 990px) {
  .register-container {
    flex-direction: column;
    height: unset;
  }
  .register-tl-box {
    width: 100%;
  }
  .register-tl-box-content {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    margin-top: 4rem;
    margin-bottom: 2rem;
  }
  .register-tl-box_title {
    margin-bottom: 2rem;
    /* h2 */
    font-size: 2.25rem; /*36px*/
    line-height: 2.75rem; /*44px*/
    letter-spacing: -0.0025rem; /*-0.4px*/
  }
  .register-tl-box_body {
    font-size: 1rem;
    line-height: 1.25rem;
  }
  .register-br-box {
    width: 100%;
  }
  .vhhp-button {
    padding-right: 2rem;
    padding-left: 2rem;
  }
}
