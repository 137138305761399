@import '../../common/common.scss';
.mg-container {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
    margin-top: 4rem;
    margin-bottom: 1rem;
}

.mg-header {
    font-family: RecifeDisplayRegular;
    font-style: normal;
    font-weight: normal;
    font-size: 1.5rem/*24px*/
    ;
    line-height: 1.75rem/*28px*/
    ;
    letter-spacing: -0.00625rem/*-0.1px*/
    ;
    margin-bottom: 2rem;
}

.mg-copy-buttons {
    text-align: center;
    padding-bottom: 2.5rem;
}

.mg-copy-buttons__button {
    margin: 0.5rem;
    min-width: 180px;
    
}

.mg-user-tabs {
    width: 100%;
    border-bottom: 1px solid $gray-2;
}

.mg-user-tab {
    font-family: MaisonNeue;
    font-style: normal;
    font-weight: normal;
    font-size: 1.25rem/*20px*/
    ;
    line-height: 2.125rem/*34px*/
    ;
    height: 100%;
    display: inline-block;
    cursor: pointer;
    padding-bottom: .5rem;
    margin-right: 3rem;
}

.mg-tab-active {
    color: $blue-1;
    border-bottom: 3px solid $blue-3;
}

.mg-tab-inactive {
    color: $gray-2;
}

.mg-tab-bubble {
    color: $blue-2;
    background-color: $blue-6;
    border-radius: 50%;
    height: 1.5rem;
    width: 1.5rem;
    padding-right: .5rem;
    padding-left: .5rem;
    margin-left: .5rem;
}

.mg-tab-header {
    margin-top: 1rem;
    margin-bottom: 0.625rem;
}

.mg-tab-description {
    font-family: MaisonNeue;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem/*16px*/
    ;
    line-height: 1.25rem;
    color: #3B3B3B;
    opacity: 0.8;
}

.mg-tab-instructions {
    font-family: MaisonNeue;
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem/*14px*/
    ;
    line-height: 1.125em/*18px*/
    ;
    color: #979797;
    opacity: 0.8;
    margin-top: 0.75rem;
}

.mg-tab-guests {
    margin-top: 1.875rem;
}

.mg-guest-th {
    font-family: MaisonNeue;
    font-style: normal;
    font-weight: 500;
    font-size: 0.6875rem/*11px*/
    ;
    line-height: 1rem/*16px*/
    ;
    letter-spacing: 0.04375rem/*0.7px*/
    ;
    text-transform: uppercase;
    color: $gray-1;
    opacity: 0.8;
}

.mg-guest {
    background-color: white;
    padding: 0.75rem;
    margin-bottom: 0.5rem;
}

.mg-guest-row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-direction: row;    
}

.mg-guest-col1 {
    width: 20%;
}

.mg-guest-col2 {
    width: 40%;
}

.mg-guest-col3 {
    width: 20%;
}

.mg-guest-col4 {
    width: 10%;
    text-align: right;
}

.mg-green-tag {
    /* Label Secondary */
    font-family: MaisonNeue;
    font-style: normal;
    font-weight: 500;
    font-size: 0.6875rem /*11px*/;
    line-height: 1rem /*16px*/;
    letter-spacing: 0.04375rem /*0.7px*/;
    text-transform: uppercase;
    /* Green 3 */
    color: #43802A;
    background: #DBF3D0;
    padding-left: .5rem;
    padding-right: .5rem;
    padding-top: .25rem;
    padding-bottom: .25rem;
    vertical-align: middle;
}

.mg-red-tag {
    /* Label Secondary */
    font-family: MaisonNeue;
    font-style: normal;
    font-weight: 500;
    font-size: 0.6875rem /*11px*/;
    line-height: 1rem /*16px*/;
    letter-spacing: 0.04375rem /*0.7px*/;
    text-transform: uppercase;
    /* Red 1 */
    color: #8F2B25;
    background: #F8CED7;
    padding-left: .5rem;
    padding-right: .5rem;
    padding-top: .25rem;
    padding-bottom: .25rem;
    vertical-align: middle;
}
.mg-guest-col4__img {
    vertical-align: middle;
    cursor: pointer;
}

@media screen and (max-width:960px) {
    .mg-container {
        margin-left: 2rem;
        margin-right: 2rem;
    }
    .mg-guest-row {
        flex-direction: column-reverse;        
    }
    .mg-guest-col1,
    .mg-guest-col2,
    .mg-guest-col3,
    .mg-guest-col4 {
        padding-bottom: .5rem;
    }
    .mg-guest-col4 {
        width: 100%;
    }
    .mg-red-tag,
    .mg-green-tag {
        white-space: nowrap;
    }
}