@import '../../../../common/common.scss';

.info-section {
  .info-left,
  .info-right {
    &__content {
      //max-width: 770px;
      height: 750px;
    }
  }

  .info-right {
    background-color: #66cfe7;
    width: 100%;

    &__content {
      //height: 100%;
      display: flex;
      flex-direction: column;
      position: relative;

      h2 {
        margin-bottom: 1.5rem;
      }
      p {
        margin-top: 1rem;
      }
      &__elem-1 {
        order: 2;
        flex: 2;
      }
      &__elem-2 {
        order: 3;
        flex: 1;
        background-color: #3f64a7;
      }
      &__elem-3 {
        order: 1;
        flex: 0.5;
        display: flex;
        align-items: center;
        background-color: $blue-1;
      }
    }

    &__my-agenda {
      padding: 2.5rem 1.25rem;
      background-color: white;
      height: 100%;

      .manage-agenda {
        display: flex;
        align-items: center;
        svg {
          transform: rotate(90deg) scale(0.7);

          margin-left: 0.5rem;
        }
      }

      &__header {
        margin-bottom: 1rem;
      }
    }

    &__agenda-list {
      display: block;
      height: 690px;
      max-width: 400px;
      ul {
        max-height: 650px;

        li {
          display: flex;
          align-items: center;
          justify-content: space-between;

          & {
            border-bottom: 1px solid rgba($color: $gray-1, $alpha: 0.2);
            margin-bottom: 1rem;
          }

          .agenda-interact {
            width: 150px;
            img {
              float: right;
            }

            .agenda-join-label {
              padding: 0 1rem 1rem 1rem;
              text-align: right;
            }
          }

          .agenda-info {
            & > * {
              margin-bottom: 0.8rem;
            }
          }
          .agenda-title {
            color: $blue-2;
            width: 200px;
          }

          .agenda-speakers {
            width: 200px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 990px) {
  .info-section {
    display: flex;

    .info-container {
      padding-left: 9.75rem;
      padding-top: 6.25rem;
    }

    .info-right {
      flex: 1;
      //height: 750px;
      height: 838px;
      max-width: 385px;
      background-color: blue;

      &__content {
        height: 750px;
        &__elem-1 {
          order: 1;
        }
        &__elem-2 {
          order: 2;
        }
        &__elem-3 {
          order: 3;
        }

        .absolute {
          position: absolute;
          width: 100%;
          height: 100%;
          align-items: flex-start;

          .home-right-elem-3 {
            p {
              span {
                img {
                  transform: rotate(180deg);
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1440px) {
}
