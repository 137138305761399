@import '../../common/common.scss';

.footer-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: $gray-1;
  padding: 3rem 2.25rem 1rem 2.25rem;
}

.footer-container_row-1 {
  width: 100%;
  display: flex;
  flex-direction: row;
  //margin-top: 3.125em;
}

.footer-container_row-1_col-logo {
  width: 20%;
  padding-left: 2em;
}

.footer-container_row-1_col-customer {
  width: 20%;
}
.footer-container_row-1_col-support {
  width: 20%;
}
.footer-container_row-1_col-contact {
  width: 20%;
}
.footer-container_row-1_col-social {
  width: 20%;
}
.footer-title {
  margin-bottom: 1.2rem;
  opacity: 0.7;
}

.customer-number {
  font-size: 0.875rem /*14px*/;
  line-height: 2rem /*36px*/;
}

.website {
  text-decoration: underline !important;
}

.footer-container_row-2_col-empty {
  width: 40%;
  padding-left: 2em;
}
.footer-container_row-2 {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 1.5rem;
  margin-top: 1rem;
  opacity: 0.7;
}

.block {
  display: block;
}
.footer-link {
  font-family: MaisonNeue;
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem /*14px*/;
  line-height: 2rem /*36px*/;
  letter-spacing: -0.00625rem /*0.1px*/;
  text-decoration: none;
  color: white;
}
.footer-link:hover,
.footer-link:active {
  color: $gray-3;
}

.external-link:after {
  padding-left: 0.625rem;
  content: url('img/arrow_nw.svg');
}

//Footer download desktop first
.footer_download {
  display: flex;
  margin-top: 1rem;
}

.footer_download img {
  //margin-right: 1rem;
}

@media screen and (max-width: 1180px) {
  .footer-container_row-1_col-logo {
    width: 25%;
  }

  .footer-container_row-1_col-customer {
    width: 25%;
  }
  .footer-container_row-1_col-support {
    width: 15%;
  }
  .footer-container_row-1_col-contact {
    width: 15%;
  }
  .footer-container_row-1_col-social {
    width: 15%;
  }

  .footer-container_row-2_col-empty {
    width: 45%;
  }
}

@media screen and (max-width: 990px) {
  .footer-container {
    padding: 2rem 0;
  }

  .footer-container_row-1 {
    flex-wrap: wrap;
  }
  .footer-container_row-1_col-logo {
    width: 100%;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  .footer-container_row-1_col-customer {
    width: 100%;
    padding-left: 1.25rem;
    padding-top: 3rem;
  }

  .footer-container_row-1_col-support,
  .footer-container_row-1_col-contact,
  .footer-container_row-1_col-social {
    width: 50%;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    padding-top: 3rem;
  }
  .footer-adddress-break {
    display: none;
  }

  .footer-container_row-2_col-empty {
    width: 0%;
    display: none;
  }
  .footer-container_row-2_col-copyright {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    padding-top: 2rem;
    width: 100%;
  }
}
