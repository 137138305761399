﻿*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
}

a,
a:visited,
a:hover {
  text-decoration: none;
}

li {
  list-style: none;
}

//Visibility
.hide-desktop {
  display: none;
}

.show-desktop {
  display: block;
}

.pointer {
  cursor: pointer;
}

//variables
$gray-1: #3b3b3b;
$gray-2: #979797;
$gray-3: #e3e3e3;
$gray-5: #f6f6f6;
$gray-6: #f5f5f5;
$blue-1: #203e68;
$blue-2: #2b538b;
$blue-3: #13819C;
$blue-4: #86a4ce;
$blue-6: #E5F6F8;
$blue-nav-link: #0b66c3;
$blue-active-highlight: rgba(102, 207, 231, 0.5);
$brand-blue-1: #00afd7;
$brand-blue-2: #66cfe7;
$brand-blue-3: #1f9dbc;
$brand-green-1: #c4d600;
$gold-6: #fff1b5;
$gold-5: #fae1c0;
$green-6: #209dbd;
$green-5: #d9e6d4;
$green-1: #284d19;
$green-2: #366622;
$red-1: #8f2b25;
$red-3: #ef473e;
$red-6: #f8ced7;
$dark-bg: #181818;
$orange-1: #FC5420;


@font-face {
  font-family: 'MaisonNeue';
  src: url('./fonts/Neue_Haas_Grotesk-Text.woff2') format('woff2'),
  url('./fonts/Neue_Haas_Grotesk-Text.woff') format('woff');
  // src: url('./fonts/MaisonNeue-Book.woff2') format('woff2'),
  //   url('./fonts/MaisonNeue-Book.woff') format('woff');
}

@font-face {
  font-family: 'RecifeDisplayBook';
  src: url('./fonts/Neue_Haas_Grotesk-Display_Medium.woff2') format('woff2'),
  url('./fonts/Neue_Haas_Grotesk-Display_Medium.woff') format('woff');
  // src: url('./fonts/RecifeDisplayWeb-Book.woff2') format('woff2'),
  //   url('./fonts/RecifeDisplayWeb-Book.woff') format('woff');
}

@font-face {
  font-family: 'RecifeDisplayRegular';
  src: url('./fonts/Neue_Haas_Grotesk-Display.woff2') format('woff2'),
  url('./fonts/Neue_Haas_Grotesk-Display.woff') format('woff');
  // src: url('./fonts/RecifeDisplayWeb-Regular.woff2') format('woff2'),
  //   url('./fonts/RecifeDisplayWeb-Regular.woff') format('woff');
}

//Color classes

.gray-1 {
  color: $gray-1;
}

.gray-3 {
  color: $gray-3;
}

.black {
  color: black;
}
.white {
  color: white;
}

.brand-blue-1 {
  color: $brand-blue-1;
}

.brand-blue-2 {
  color: $brand-blue-2;
}

.brand-blue-2-bg {
  background-color: $brand-blue-2;
}

.brand-green-1-bg {
  background-color: $brand-green-1;
}

.brand-blue-3 {
  color: $brand-blue-3;
}
.blue-3 {
  color: $blue-3;
}
.red-error {
  color: #ef473e;
}

.orange-1 {
  color: $orange-1;
}

.body,
.body1 {
  font-family: MaisonNeue;
  font-style: normal;
  font-weight: normal;
  font-size: 1.25rem /*20px*/;
  line-height: 2.125rem /*34px*/;
}

.body2 {
  font-family: MaisonNeue;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem /*16px*/;
  //line-height: 1.25rem; /*20px*/
  line-height: 1.5rem; /*24px*/
}

.body3 {
  font-family: MaisonNeue;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem /*14px*/;
  line-height: 1.25em /*20px*/;
}

.display {
  font-family: RecifeDisplayBook;
  font-style: normal;
  font-weight: normal;
  font-size: 3.75rem /*60px*/;
  line-height: 3.875rem /*62px*/;
  letter-spacing: -0.025rem /*-0.4px*/;
}

.h1,
.h2,
.h3 {
  font-family: RecifeDisplayBook;
  font-style: normal;
  font-weight: normal;
}
.h1 {
  font-size: 3rem /* 48px*/;
  line-height: 3.25rem /*52px*/;
  letter-spacing: -0.025rem /*-0.4px*/;
}

.h2 {
  font-size: 2.25rem; /*36px*/
  line-height: 2.75rem; /*44px*/
  letter-spacing: -0.0025rem; /*-0.4px*/
}

.h3 {
  font-size: 1.5rem /*24px*/;
  line-height: 1.75rem /*28px*/;
  letter-spacing: -0.00625rem /*-0.1px*/;
}

.h4 {
  font-family: RecifeDisplayRegular;
  font-style: normal;
  font-weight: normal;
  font-size: 1.125rem /*18px*/;
  line-height: 1.25rem /*20px*/;
  letter-spacing: -0.00625rem /*-0.1px*/;
}

.formGroup {
  margin-bottom: 1rem;
}

.label--primary {
  font-family: MaisonNeue;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem /*14px*/;
  line-height: 1.25rem /*20px*/;
  letter-spacing: 0.0025rem /*0.4px*/;
  text-transform: uppercase;
}

.label--secondary {
  font-family: MaisonNeue;
  font-style: normal;
  font-weight: 500;
  font-size: 0.6875rem /*11px*/;
  line-height: 1rem /*16px*/;
  letter-spacing: 0.04375rem /*0.7px*/;
  text-transform: uppercase;
}

.input-error {
  font-family: MaisonNeue;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.0025rem;
}

.input {
  width: 100%;
  padding: 1rem 1rem 0.875rem 0.875rem;
  font-family: MaisonNeue;
  line-height: 1.25rem;
  font-size: 1rem;
  border: 1px solid #e3e3e3;
  outline: none;
  box-sizing: border-box;
}

.input--checkbox {
  width: unset;
  display: unset;
  vertical-align: middle;
  border-radius: 0;
  height: 1.5rem /*24px*/;
  width: 1.5rem /*24px*/;
  margin-right: 0.75rem;
  background: #ffffff;
  border: 1px solid #e3e3e3;
  box-shadow: 0px 0px 5px $blue-active-highlight;
}

input--checkbox:checked {
  background-color: $brand-blue-2; // should ne 2
  color: $gray-1;
}

input--checkbox + .label {
  display: inline;
}

.input:focus {
  box-shadow: 0px 0px 5px $blue-active-highlight;
  border: 2px solid $brand-blue-2; //shoul be 2
  /*border: 2px solid #66CFE7;*/
  /*box-sizing: border-box;*/
  /*box-shadow: 0px 0px 5px rgba(102, 207, 231, 0.5);*/
}

.button {
  width: 100%;
  padding: 1rem 1rem 0.875rem 0.875rem;
  font-family: MaisonNeue;
  line-height: 1.25rem /*20px*/;
  font-size: 1rem;
  border: none;
  outline: none;
  box-sizing: border-box;
  border-radius: 0;
  background: $blue-3;
  /*box-shadow: 0px -2px 8px rgba(0,0,0, 0.1);*/
  color: white;
}

.button:hover,
.button:focus {
  background: $blue-2;
}

.a {
  font-family: MaisonNeue;
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem /*14px*/;
  line-height: 1.5rem /*24px*/;
  letter-spacing: -0.00625rem /*0.1px*/;
  text-decoration: none;
  color: $blue-3;
}

.mb-1 {
  margin-bottom: 1rem;
}

.a:hover,
.a:active {
  color: $blue-2;
}

.link-button:link,
.link-button:visited {
  display: inline-block;
  text-align: center;
  width: 100%;
  font-size: 1rem;
  color: white;
  text-decoration: none;
  padding: 0.875rem 1.75rem 0.875rem 1.75rem;
  line-height: 20px /*1.25em*/;
  border-radius: 0;
  border: none;
  outline: none;
  background-color: $blue-3;
  /*box-shadow: 0px -2px 8px rgba(0,0,0, 0.1);*/
}

.link-button:hover,
.link-button:focus,
.link-button:active {
  background: $blue-2;
}

.lb-white {
  /* temp margin-top - whole class is a bit of a hack*/
  margin-top: 200px;
  width: unset !important;
  border: 1px solid white !important;
  background-color: unset !important;
  position: absolute;
}

//Flex
.flex {
  display: flex;
}

.container {
  &--pall {
    padding: 1.25rem;
  }

  &--py {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  &--px {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  &--pl {
    padding-left: 1.25rem;
  }

  &--pt {
    padding-top: 1.25rem;
  }

  &--pb {
    padding-bottom: 1.25rem;
  }
}

.scroll {
  overflow: hidden;

  overflow-y: scroll;

  .dropdown__menu-item {
    padding-bottom: 1rem;
  }
}

.scroll::-webkit-scrollbar {
  width: 5px;
  height: 20px;
}

.scroll::-webkit-scrollbar-track {
  background-color: transparent;
}

.scroll::-webkit-scrollbar-thumb {
  background-color: rgba($color: gray, $alpha: 0.7);
  border-radius: 20px;
}

@media screen and (min-width: 765px) {
  //Spacing
  .container {
    &--pall {
      padding: 3.25rem;
    }

    &--py {
      padding-top: 3.25rem;
      padding-bottom: 3.25rem;
    }

    &--px {
      padding-left: 3.25rem;
      padding-right: 3.25rem;
    }

    &--pl {
      padding-left: 3.25rem;
    }

    &--pt {
      padding-top: 3.25rem;
    }

    &--pb {
      padding-bottom: 3.25rem;
    }
  }
}

// .intercom-launcher {
//   &::after{
//     content: 'Need Help?';
//     color: $blue-3;
//     position: absolute;
//     width: 180px;
//     bottom: -20px;
//     left: -0.4rem;
//     font-family: MaisonNeue;
//   font-style: normal;
//   font-weight: normal;
//   font-size: 0.875rem /*14px*/;
//   line-height: 1.25em /*20px*/;
//   }
// }

.help {
  color: $blue-3;
  position: fixed;
  width: 180px;
  bottom: 0;
  right: -100px;
  font-family: MaisonNeue;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem /*14px*/;
  line-height: 1.25em /*20px*/;
  display: none;
}

@media screen and (min-width: 990px) {
  //Spacing
  .container {
    &--pall {
      padding: 5.25rem;
    }

    &--py {
      padding-top: 5.25rem;
      padding-bottom: 5.25rem;
    }

    &--px {
      padding-left: 5.25rem;
      padding-right: 5.25rem;
    }

    &--pl {
      padding-left: 5.25rem;
    }

    &--pt {
      padding-top: 5.25rem;
    }

    &--pb {
      padding-bottom: 5.25rem;
    }
  }
}

@media screen and (max-width: 990px) {
  .h1 {
    /*becomes h2 */
    font-size: 2.25rem; /*36px*/
    line-height: 2.75rem; /*44px*/
    letter-spacing: -0.0025rem; /*-0.4px*/
  }

  //Visibiltiy
  .hide-mobile {
    display: none;
  }

  .show-mobile {
    display: block;
  }

  .display {
    /*becomes h2*/
    font-size: 2.25rem /* 48px*/;
    line-height: 2.75rem /*52px*/;
    letter-spacing: -0.025rem /*-0.4px*/;
  }
}

@media screen and (min-width: 1440px) {
  //Spacing
  .container {
    &--pall {
      padding: 9.25rem;
    }

    &--py {
      padding-top: 9.25rem;
      padding-bottom: 9.25rem;
    }

    &--px {
      padding-left: 9.25rem;
      padding-right: 9.25rem;
    }

    &--pl {
      padding-left: 9.25rem;
    }

    &--pt {
      padding-top: 9.25rem;
    }

    &--pb {
      padding-bottom: 9.25rem;
    }
  }
}

@media screen and (min-width: 1660px) {
  .wrapper-def {
    margin-right: auto;
    margin-left: auto;
    max-width: 1200px;
  }
}
