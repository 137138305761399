@import '../../common/common.scss';

.pp-container {
    position: relative;
}

.pp-body {}

.pp-header {
    width: 100%;
    border-bottom: 1px solid $gray-2;
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
}

.pp-header-left {
    margin-left: 2rem;
    margin-right: 2rem;
    margin-top: auto;
    margin-bottom: auto;
}

.pp-header__title {
    margin-bottom: 1rem;
}

.pp-header__text {
    margin-bottom: 1rem;
}

.pp-header__avatar {
    /* position: absolute;
    top: 2.5rem;
    right: 2.5rem;
    transform-origin: top right; */
}

.pp-upload {
    margin-left: 2rem;
    margin-right: 2rem;
}

.pp-selection {
    margin-left: 2rem;
    margin-right: 2rem;
    border-top: 1px solid $gray-3;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.pp-footer {
    border-top: 1px solid $gray-2;
    padding-top: 1rem;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    padding-bottom: 1.5rem;
}

.pp-footer__cancel-button {
    margin-top: 1rem;
    display: inline-block;
    font-family: MaisonNeue;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    padding-left: 1rem;
    cursor: pointer;
    letter-spacing: 0.1px;
    color: #3B3B3B;
    opacity: 0.8;
}

.pp-footer__save-button {
    float: right;
}

.pp-avatar {
    margin: 1rem;
    cursor: pointer;    
}
.pp-avatar-preview {
    margin: 0;
}

.pp-avatar--selected {
    box-shadow: 0px 0px 5px $blue-active-highlight;
    border: 2px solid $blue-active-highlight;
    border-radius: 50%;
    /* border: 2px solid var(--gray-2);
   border-radius: 5px; */
}

.avatar--small {}

.avatar--big {
    height: 120px;
}

.pp-file-wrap {
    position: relative;
    text-align: center;
    margin: 2rem;
}

.pp-file-input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.pp-file-input+label {
    display: inline-block;
    padding: 1rem 2rem;
    font-family: MaisonNeue;
    line-height: 1.25rem;
    font-size: 1rem;
    outline: none;
    box-sizing: border-box;
    border-radius: 0;
    cursor: pointer;
    color: $blue-3;
    background-color: white;
    border: 1px solid $blue-3;
    /* pointer-events: none;     */
}

.pp-file-input:focus+label, .pp-file-input+label:hover {
    background: $blue-6;
}

.pp-crop {
    width: 100%;
}
.pp-react-crop-container {
    display: flex;
    width: 100%;
    justify-content: center;    
}
.pp-react-crop {
    margin-top: 1rem;
    margin-bottom: 1rem;    
}

.pp-crop-action-row {
    display: flex;
    align-content: center;
    justify-content: space-between;

    /* margin-top: 1rem;
    margin-left: 2.5rem;
    margin-right: 2.5rem;
    margin-bottom: 1.5rem; */
}
.pp-preview {
    text-align: center;
}
.pp-crop-preview {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;    
    justify-content: center; 
}
.pp-crop-action-buttons {

}
.pp-crop-action-cancel{
    margin-top: auto;
}
.pp-crop-action__cancel {
    margin-bottom: 1rem;
}
.pp-crop-action-save {
    margin-top: auto;
}
.pp-crop-action__save {
    
}
@media screen and (max-width: 960px) {
    /* .pp-crop-action-row {
        margin: 0;
    }
    .pp-avatar {
        margin: 0;
    } */

    .pp-footer {
        padding: 0;
    }
}