@import '../../common/common.scss';

.use-desktop {
  background-color: $blue-3;
  height: 100vh;

  &__text {
    padding-top: 3rem;
    margin-bottom: 2rem;
  }

  &__cta {
    button {
      margin-bottom: 2rem;
    }
  }
}
