@import '../../common/common.scss';

.submenu {
  padding-top: 3rem;
  margin-bottom: 1rem;
  display: flex;
  padding-left: 1.25rem;
  svg {
    transform: rotate(-90deg);
    margin-right: 1rem;
    margin-top: 0.45rem;
  }

  &__route {
  }

  .slash-white::before {
    content: '/';
    padding: 0 0.3rem;
    color: white;
  }

  .slash-dark::before {
    content: '/';
    padding: 0 0.3rem;
    color: $gray-1;
  }

  .slash-blue::before {
    content: '/';
    padding: 0 0.3rem;
    color: $blue-3;
  }
}

@media screen and (min-width: 990px) {
  .submenu {
    padding-left: 0;
    padding-top: 0;
    margin-bottom: 2rem;
  }
}
